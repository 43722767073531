@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProBlack.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProBlackOblique.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProBook.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProBookOblique.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProHeavy.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProHeavyOblique.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProLight.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProLightOblique.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProMedium.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProMediumOblique.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProOblique.otf") format("opentype");
}

@font-face {
  font-family: "Avenir LT Pro";
  src: url("../public/fonts/AvenirLTProRoman.otf") format("opentype");
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c0bfbf;
}
@-moz-document url-prefix() {
  * {
    scrollbar-color: #cccccc;
    scrollbar-width: thin;
  }
}
